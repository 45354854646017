@tailwind base;
@tailwind components;
@tailwind utilities;

.select {
  @apply appearance-none bg-white border rounded px-2 py-1 cursor-pointer;
  padding-left: 1em;
  padding-right: 3em;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      theme("colors.gray.500") 50%
    ),
    linear-gradient(135deg, theme("colors.gray.500") 50%, transparent 50%),
    linear-gradient(
      to right,
      theme("colors.gray.200"),
      theme("colors.gray.200")
    );
  background-position: calc(100% - 17px) calc(50%), calc(100% - 12px) calc(50%),
    calc(100% - 2em);
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.select:hover {
  @apply bg-gray-100;
}

$popover-bg: white;
$popover-border-color: theme('colors.gray.400');
$popover-border-width: theme('borderWidth.default');
$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;
$popover-arrow-outer-color: $popover-border-color;
$border-radius-lg: theme('borderRadius.default');

.popover {
  @apply .shadow-lg .rounded .z-50 absolute;

  border-color: $popover-border-color;
  border-width: $popover-border-width;
  background-color: $popover-bg;

  .arrow {
    position: absolute;
    display: block;
    width: $popover-arrow-width;
    height: $popover-arrow-height;
    margin: 0 $border-radius-lg;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border-color: transparent;
      border-style: solid;
    }
  }

  &.top {
    margin-bottom: $popover-arrow-height;

    .arrow {
      bottom: calc(($popover-arrow-height + $popover-border-width) * -1);
    }

    .arrow::before,
    .arrow::after {
      border-width: $popover-arrow-height calc($popover-arrow-width / 2) 0;
    }

    .arrow::before {
      bottom: 0;
      border-top-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      bottom: $popover-border-width;
      border-top-color: $popover-arrow-color;
    }
  }
  &.right {
    margin-left: $popover-arrow-height;

    .arrow {
      left: calc(($popover-arrow-height + $popover-border-width) * -1);
      width: $popover-arrow-height;
      height: $popover-arrow-width;
      margin: $border-radius-lg 0;
    }

    .arrow::before,
    .arrow::after {
      border-width: calc($popover-arrow-width / 2) $popover-arrow-height
        calc($popover-arrow-width / 2) 0;
    }

    .arrow::before {
      left: 0;
      border-right-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      left: $popover-border-width;
      border-right-color: $popover-arrow-color;
    }
  }
  &.bottom {
    margin-top: $popover-arrow-height;

    .arrow {
      top: calc(($popover-arrow-height + $popover-border-width) * -1);
    }

    .arrow::before,
    .arrow::after {
      border-width: 0 calc($popover-arrow-width / 2) $popover-arrow-height
        calc($popover-arrow-width / 2);
    }

    .arrow::before {
      top: 0;
      border-bottom-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      top: $popover-border-width;
      border-bottom-color: $popover-arrow-color;
    }
  }
  &.left {
    margin-right: $popover-arrow-height;

    .arrow {
      right: calc(($popover-arrow-height + $popover-border-width) * -1);
      width: $popover-arrow-height;
      height: $popover-arrow-width;
      margin: $border-radius-lg 0;
    }

    .arrow::before,
    .arrow::after {
      border-width: calc($popover-arrow-width / 2) 0 calc($popover-arrow-width / 2)
        $popover-arrow-height;
    }

    .arrow::before {
      right: 0;
      border-left-color: $popover-arrow-outer-color;
    }

    .arrow::after {
      right: $popover-border-width;
      border-left-color: $popover-arrow-color;
    }
  }
}
